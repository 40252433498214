import {useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";

function CertificatesPage() {

    const {t, i18n} = useTranslation();

    const [certificate, setSertificate] = useState([]);

    const dataYears = [2024, 2023, 2022, 2021, 2020, 2019, 2014]

    const [filterYear, setFilterYear] = useState(0);

    const [load, setLoad] = useState(true);

    useEffect(()=> {

        axios.get(
            'https://api.lukyanchuk.com/api/front-end/resume/certificate?data=DESC'
        ).then(function (response) {
            const getSertificate = response.data.data;
            setSertificate(getSertificate);
            console.log(getSertificate);

            setLoad(false);
        }).catch(err => {
            // In case it errors.
            console.log(err);
        });

    }, []);

    const handleFilter = (value) => {
        setFilterYear(value)
    }

    return <div className={'container min-vh-100 pb-5 pt-5'}>


        <small>
            <a className={'text-dark text-decoration-none'} href={'/'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                </svg>  {t('title.back')}
            </a>
        </small>

        <div className="col-md-12 col-12 text-start">
            <h1 className={'display-1'}>{t('title.certificates')}</h1>
            <br/>
            <small className="text-secondary">
                {t('subtitle')}
            </small>
        </div>

        <div className="d-flex flex-wrap">
            <div className="col-md-">
                <div className={'pt-2 p-1'}>
                    <button className="btn bg-secondary-subtle p-2 rounded" onClick={() => handleFilter(0)}>
                        <small> {t('title.all')} </small>
                    </button>
                </div>
            </div>
            {
                load ? 'Loading...' :  dataYears.map((year, i) => <div className="col-md-">
                    <div className={'pt-2 p-1'}>
                        <button className="btn bg-secondary-subtle p-2 rounded" onClick={() => handleFilter(year)}>
                            <small> {year}</small>
                        </button>
                    </div>
                </div>)
            }


        </div>

        <div className="d-flex flex-wrap mt-5">

            {
                load ? 'Loading...' :  certificate.length > 0 && certificate.filter((a) => filterYear != 0 ? a.data.split('-')[0] == filterYear : true).map((item, i) => <div key={i} className="col-md-4">
                    <div className={'p-2'}>
                        <h4>{
                            item.name
                        }</h4>
                        <small className="text-secondary">{item.data.replace('-', '.').replace('-', '.').split('.').reverse().join('.')}</small>
                        <a href={item.url} className={'lead text-dark text-decoration-none'}>
                            <small className={'text-secondary p-2'}>
                                {t('title.view')} <svg
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                                <path fill-rule="evenodd"
                                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                            </svg>
                            </small>
                        </a>
                    </div>
                </div>)
            }


        </div>

        <div id={'bottom-menu-links'} className="row mt-5 mb-5">
            <div className="col-md-6 text-md-end text-center">
                <a className={'lead text-dark text-decoration-none'} href={'/'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                    </svg>
                    {t('title.back')}</a>
            </div>
            <div className="col-md-6 border-start text-md-start text-center mt-md-0 mt-5">
                <a href="https://lukyanchuk.com/cooperation" className={'lead text-dark text-decoration-none'}>{t('title.get-in-touch')} <svg
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                    <path fill-rule="evenodd"
                          d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                </svg></a>
            </div>
        </div>

    </div>
}

export default CertificatesPage;
