import {useEffect, useState} from "react";
import DataScienceWork from '../../data/science-work';
import {useTranslation} from "react-i18next";

function AboutMePage() {

    const {t, i18n} = useTranslation();

    const links = [
        {
            "title": "Email",
            "url": "mailto:yarik@lukaynchuk.com",
            "icon": '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"\n' +
                '                                         className={\'bi bi-envelope\'} viewBox="0 0 16 16">\n' +
                '                                        <path\n' +
                '                                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>\n' +
                '                                    </svg>'
        },
        // {
        //     "title": "Instagram",
        //     "url": "https://instagram.com/yariklukaynchuk",
        //     "icon": '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">\n' +
        //         '  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>\n' +
        //         '</svg>'
        // },
        // {
        //     "title": "Facebook",
        //     "url": "https://www.facebook.com/yarik.lukyanchuk/",
        //     "icon": '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">\n' +
        //         '  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>\n' +
        //         '</svg>'
        // },
        // {
        //     "title": "X/Twitter",
        //     "url": "https://twitter.com/yariklukyanchuk",
        //     "icon": '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">\n' +
        //         '  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15"/>\n' +
        //         '</svg>'
        // },
        {
            "title": "LinkedIn",
            "url": "www.linkedin.com/in/yaroslav-lukyanchuk-a028481ab",
            "icon": '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">\n' +
                '  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>\n' +
                '</svg>'
        },
        {
            "title": "GitHub",
            "url": "https://github.com/yarikthe",
            "icon": '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">\n' +
                '  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"/>\n' +
                '</svg>'
        }
    ];

    return <div className={'container min-vh-100 pb-5 pt-5'}>

        <small>
            <a className={'text-dark text-decoration-none'} href={'/'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                </svg>  {t('title.back')}
            </a>
        </small>

        <div className="col-md-12 col-12 text-start">
            <h1 className={'display-1'}> {t('title.about')}</h1>
            <br/>
            <small className="text-secondary">
                {t('subtitle')}
            </small>
        </div>

        <div className="col-md-12 col-12 text-start mt-5">
            {/*<img*/}
            {/*    src="https://lukyanchuk.com/assets/image/me.jpg"*/}
            {/*    alt="image"*/}
            {/*     className={'img-fluid rounded-5'} style={{*/}
            {/*    height: "65vh",*/}
            {/*    width: '100%',*/}
            {/*    objectFit: 'cover'*/}
            {/*/!*}}/>*!/*/}
            {/*<small className="text-secondary m-5 mt-0 mb-0">*/}
            {/*    <i>Yaroslav Lukyanchuk (2017)</i>*/}
            {/*</small>*/}
        </div>

        <div id={'about'} className="row mt-5">
            <div className="col-md-3 text-end">
                <h2 className={'lead'}>🧑‍💻</h2>
            </div>
            <div className="col-md-9">

            {/*    <p>*/}
            {/*        I have a passion for design and am always looking for ways to incorporate it into my engineering*/}
            {/*        work.*/}
            {/*    </p> <p>*/}
            {/*    In addition to coding, I also make YouTube videos, where I focus on tech gear, creative vlogs, and a bit*/}
            {/*    of personal development.*/}
            {/*</p> <p>*/}
            {/*    When I'm not at my desk I am probably lifting weights, or at a coffee shop :)*/}
            {/*</p>*/}


                <div id={'education-and-resume_link'} className={'row align-items-center'}>
                    <div className="col-md-9">
                        <p className={'lead'}>
                            {t('text.about.title')}
                        </p>
                        <p className={'text-secondary'}>
                            {t('text.about.paragraph')}
                        </p>
                    </div>
                    <div className={'col-md-3 text-center'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor"
                             className="bi bi-file-person" viewBox="0 0 16 16">
                            <path
                                d="M12 1a1 1 0 0 1 1 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 0 1 1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                            <path d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg>
                        <br/><br/>
                        {/*<span>More in my </span>*/}
                        <a href="https://resume.lukyanchuk.com" className={'text-dark'}>{t('title.resume')} <svg
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                            <path fill-rule="evenodd"
                                  d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                        </svg></a>
                    </div>
                </div>

                {/*<div id={'skills'} className="col-md- skills mt-5">*/}
                {/*    <h5 className={'lead'}>Skills</h5>*/}
                {/*    <div className="d-flex flex-wrap">*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    Flutter*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    Laravel*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    React JS*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    MySQL*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    Git*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    Google Cloud*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    API*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    Firebase*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    PHP*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    HTML*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    CSS/Bootstrap*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-">*/}
                {/*            <div className={'pt-2 p-1'}>*/}
                {/*                <small className="bg-secondary-subtle p-2 rounded">*/}
                {/*                    JavaScript*/}
                {/*                </small>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div id={'certificates'} className="col-md- certificates mt-5">


                    <div className="d-flex justify-content-between align-items-center pb-3">
                        <h5 className={'lead'}>{t('title.certificates')}</h5>

                        <a href="/certificates" target={'_blank'} className={'text-dark'}>
                            {t('title.view-more')} <svg
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                            <path fill-rule="evenodd"
                                  d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                        </svg>
                        </a>
                    </div>

                    <div className="d-flex flex-wrap">
                        <div className="col-md-4 col-12 p-2">
                            <div className="rounded-4 border p-3 text-center">
                                <a href="https://drive.google.com/file/d/1KBORokmwnZWHpMOnEvtRoySAAAwZhPTH/view" target={'_blank'}>
                                    <img src="/files/certificate.png" alt="google-ai.png" className={'img-fluid rounded-2'} style={{height: '325px', objectFit: "contain"}}/>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-3 col-12 p-2">
                            <div className="rounded-4 border p-3 text-center">

                                <img src="https://www.gstatic.com/devrel-devsite/prod/v18deffdce717053d2574d396ca1a06d6589d5eab7eb54fc25710e3ed98d4a3ba/developers/images/lockup-new.svg" alt="google-for-developers" className={'img-fluid mb-2'} style={{height: '65px'}}/>
                                <h6>Badges on Google for Developers</h6>
                                <a href="https://developers.google.com/profile/u/lukyanchuk" target={'_blank'}>{t('title.view')}</a>

                            </div>

                            <div className="rounded-4 border p-3 text-center mt-2">

                                <img src="https://storage.googleapis.com/exceedlms-external-uploads-production/uploads/organizations/branding_logos/9/full/logo-google-fullcolor-3x-464x153px.png" alt="google" className={'img-fluid mb-2'}/>
                                <h6>Google Certificates on Skillshop Exceedlms</h6>
                                <a href="https://skillshop.exceedlms.com/profiles/bcc33cac2c504147b8c9563ffce8f94a" target={'_blank'}>{t('title.view')}</a>

                            </div>
                        </div>

                        {/*<div className="col-md-3 col-12 p-2">*/}
                        {/*    */}
                        {/*</div>*/}

                        <div className="col-md-5 col-12 p-2">
                            <div className="rounded-4 border p-3 text-center">

                                <img src="/files/google-ai.png" alt="google-ai-certificate" className={'img-fluid mb-2'}/>
                                {/*<h6>Основи AI by Google & Prjctr</h6>*/}

                            </div>
                        </div>

                    </div>
                </div>


                <div id={'media'} className="row mt-5">

                    <div className="col-md-5">
                        <h2 className={'lead'}>{t('title.media')}</h2>

                        <small className="text-secondary">
                            26.06.2020
                        </small>
                        <h4>
                            Обдарована та перспективна житомирська молодь...
                        </h4>
                        <p>
                            «Бажаю, щоб та енергія, яка є у вас сьогодні,  залишалася з вами назавжди. Як би важко не було в житті, творіть свій світ, і тоді все навколо зміниться…
                        </p>

                        <a href="https://zt-rada.gov.ua/?pages=11342" className={'text-dark text-decoration-none-'} target={'_blank'}>{t('title.read')} {t('title.detail')} <svg
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                            <path fill-rule="evenodd"
                                  d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                        </svg></a>
                    </div>

                    <div className="col-md-7">
                        <img
                            src="https://zt-rada.gov.ua/files/upload/sitephotos/img1593175223_6.jpg"
                            alt="image"
                            className={'img-fluid rounded-5'} style={{
                            height: "35vh",
                            width: '100%',
                            objectFit: 'cover'
                        }}/>
                    </div>
                </div>

                {/*<div className="col-md- science-work">*/}
                {/*    <h5 className={'lead'}>Science work</h5>*/}
                {/*    {*/}
                {/*        data.length > 0 && data.slice(0, 2).map((item, i) => <div id={i} className="row mt-5 align-items-center">*/}
                {/*            <div className="col-md-1 text-end">*/}
                {/*                <small className={'text-secondary'}>{item.year}</small>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-9">*/}
                {/*                <small className="text-secondary">*/}
                {/*                    {item.type}*/}
                {/*                </small>*/}
                {/*                <h4>*/}
                {/*                    {*/}
                {/*                        item.title*/}
                {/*                    }*/}
                {/*                </h4>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-2">*/}
                {/*                <a href={item.link} target={'_blank'} className={'text-decoration-none text-secondary d-flex align-items-center'}>*/}
                {/*                    <small className={'m-1'}>*/}
                {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                {/*                             className="bi bi-download" viewBox="0 0 16 16">*/}
                {/*                            <path*/}
                {/*                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>*/}
                {/*                            <path*/}
                {/*                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>*/}
                {/*                        </svg> download</small>*/}
                {/*                    |*/}
                {/*                    <small className={'m-1'}> read  <svg*/}
                {/*                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                {/*                        className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">*/}
                {/*                        <path fill-rule="evenodd"*/}
                {/*                              d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>*/}
                {/*                        <path fill-rule="evenodd"*/}
                {/*                              d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>*/}
                {/*                    </svg></small>*/}

                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>)*/}
                {/*    }*/}

                {/*    <div className="text-center pt-5 mb-5">*/}
                {/*        <a href="/science-work" target={'_blank'} className={'btn btn-dark p-2'}>view more</a>*/}
                {/*    </div>*/}
                {/*</div>*/}


            </div>
        </div>


        {/*<div id={'connect'} className="row mt-5">*/}
        {/*    <div className="col-md-3 text-end">*/}
        {/*        <h2 className={'lead'}>Connect</h2>*/}
        {/*    </div>*/}
        {/*    <div className="col-md-9">*/}
        {/*        <p>*/}
        {/*            Have a question or just want to chat? Feel free to email me. Try finding me anywhere else at @yariklukyanchuk*/}
        {/*        </p>*/}
        {/*        <div className="d-flex flex-wrap">*/}
        {/*            {*/}
        {/*                links.map((item, i) =>  <div className="col-md-4">*/}
        {/*                    <a href={item.url}  target={'_blank'} className={'m-1 lead text-dark text-decoration-none d-flex justify-content-between align-items-center border rounded-2 p-3 pt-1 pb-1'}>*/}
        {/*                        <div className={'d-flex align-items-center p-0'}>*/}
        {/*                            <div dangerouslySetInnerHTML={{__html: item.icon}} />*/}
        {/*                            <small className={'m-2 mt-3'}>{item.title}</small>*/}
        {/*                        </div>*/}
        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"*/}
        {/*                             className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">*/}
        {/*                            <path fill-rule="evenodd"*/}
        {/*                                  d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>*/}
        {/*                            <path fill-rule="evenodd"*/}
        {/*                                  d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>*/}
        {/*                        </svg>*/}
        {/*                    </a>*/}
        {/*                </div>)*/}
        {/*            }*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <div id={'work'} className="row mt-5">
            <div className="col-md-3 text-end">
                <h2 className={'lead'}>{t('title.work')}</h2>
            </div>
            <div className="col-md-9">

                {/*<div className="d-flex flex-wrap">*/}

                {/*    {*/}
                {/*        dataWork.length > 0 && dataWork.filter((a, b) => a.show === 1).map((item, i) => <div key={i} className="col-auto p-2">*/}
                {/*            <div className="p-2 rounded-5 shadow-lg">*/}
                {/*                <div className="d-flex">*/}
                {/*                    <div className="col-auto p-2">*/}
                {/*                        <div className="bg-secondary rounded-circle text-white align-items-center d-flex justify-content-center" style={{height: "50px", width: "50px"}}>*/}
                {/*                            {item.img}*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="col-auto p-2">*/}
                {/*                        <h5>{item.position}</h5>*/}
                {/*                        <span>{item.company}</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="col-auto p-2">*/}
                {/*                        <small className="text-secondary m-2">*/}
                {/*                            {item.year}*/}
                {/*                        </small>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>)*/}
                {/*    }*/}

                {/*</div>*/}

                <h5 className={'lead'}>{t('title.products')}</h5>

                <div className="row vh-50">

                    <div className="col-md-4 border1 p-2 text-center">

                        <img src="https://global-uploads.webflow.com/642d682a6e4ca0d303c81fdf/650174fce8df4e241b3bdbc1_iPhone%2015%20Pro%20Max%20Titanium%20Mockup%20Front.webp" alt="app" className={'img-fluid shadow-lg1 rounded-2'} style={{height: "300px"}}/>

                        <small className={'text-secondary'}>{t('title.mobile-app')}</small>
                        <h4>
                            Random App
                        </h4>
                        <br/>
                        <a href="https://play.google.com/store/apps/details?id=com.lukyanchuk.random_app"
                           className={'bg-dark text-white text-decoration-none border rounded-5 p-2 pt-3 pb-3 shadow-lg1'} target={'_blank'}>Google Play Store <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                                                                                                                         className="bi bi-download" viewBox="0 0 16 16">
                            <path
                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                            <path
                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                        </svg></a>
                        <br/>
                        <br/>
                        <a href="https://apps.lukyanchuk.com/app/random-app"
                           className={'text-dark text-decoration-none1 m-1 p-3'} target={'_blank'}>
                            {t('title.detail')}
                            <svg
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                            <path fill-rule="evenodd"
                                  d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                        </svg></a>

                    </div>


                    <div className="col-md-4 border1 p-2 text-center">

                        <img src="https://books.lukyanchuk.com/image/cover/book_cover.png" alt="book" className={'img-fluid shadow-lg rounded-2 m-auto'} style={{height: "280px"}} />
                        <br/>
                        <br/>
                        <small className={'text-secondary'}>{t('title.book')}</small>
                        <h4>
                            1% Змін. Час зроби це зараз!
                        </h4>
                        <br/>
                        <a href="https://books.lukyanchuk.com/book/one-percent-changes-do-it-now?fbclid=IwZXh0bgNhZW0CMTAAAR3pjh_NIw9m0K-Sjz7DKBe5Z2RXrT_eJNYBMgzD64aFk57GFjQOuO97cis_aem_AZc16iLVP5PqbtW-BqMu2FEzPMKx-Sz5baAZprMrX9UVzu0bJOHkS83Z2Jg14kawYTBujEatPG7zZScZzW5f76pG"
                           className={'text-dark text-decoration-none border rounded-5 p-3 shadow-lg1'} target={'_blank'}>{t('title.detail')} <svg
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                            <path fill-rule="evenodd"
                                  d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                        </svg></a>
                    </div>

                    <div className="col-md-4 pt-1">
                        <div className="d-flex flex-wrap">
                            <iframe className={'w-100'} height="315" src="https://www.youtube.com/embed/npXoUocYrRE?si=jAOCF9eiewl0biLe"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen />
                        </div>
                        <br/>
                        <h5 className={'lead'}>{t('text.course.title')} </h5>
                        <small className="text-secondary">
                            {t('text.course.subtitle')} <a href="https://www.youtube.com/watch?v=npXoUocYrRE&list=PL6N3szFzf6LsJltveomTuF0I689KjX1-A">YouTube</a>
                        </small>

                    </div>
                </div>



                <div className="col-md- science-work border-top pt-5 mt-3">

                    <div className="d-flex justify-content-between align-items-center pb-3">
                        <h5 className={'lead'}>{t('title.science-work')}</h5>

                        <a href="/science-work" target={'_blank'} className={'text-dark'}>
                            {t('title.view-more')} <svg
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                            <path fill-rule="evenodd"
                                  d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                        </svg>
                        </a>
                    </div>

                    {
                        DataScienceWork.length > 0 && DataScienceWork.slice(0, 2).map((item, i) => <div id={i} className="row mt-5 align-items-center">
                            <div className="col-md-1 text-end">
                                <small className={'text-secondary'}>{item.year}</small>
                            </div>
                            <div className="col-md-8">
                                <small className="text-secondary">
                                    {item.type}
                                </small>
                                <h4>
                                    {
                                        i18n.language === 'ua' ? item.title : item.title_en
                                    }
                                </h4>
                            </div>
                            <div className="col-md-3">
                                <a href={item.link} target={'_blank'} className={'text-decoration-none text-secondary d-flex align-items-center'}>
                                    <small className={'m-1'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                             className="bi bi-download" viewBox="0 0 16 16">
                                            <path
                                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path
                                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                        </svg> {t('title.download')}</small>
                                    |
                                    <small className={'m-1'}> {t('title.read')}  <svg
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                              d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                                        <path fill-rule="evenodd"
                                              d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                                    </svg></small>

                                </a>
                            </div>
                        </div>)
                    }
                </div>


            </div>
        </div>

        <div id={'connect-footer'} className="row mt-5">
            <div className="col-md-3 text-end">
                <h2 className={'lead'}>{t('title.connect')}</h2>
            </div>
            <div className="col-md-9">
                <p className={'text-secondary'}>
                    {t('text.connect')}
                </p>
                <div className="d-flex flex-wrap">
                    {
                        links.map((item, i) =>  <div className="col-md-4">
                            <a href={item.url}  target={'_blank'} className={'m-1 lead text-dark text-decoration-none d-flex justify-content-between align-items-center border rounded-2 p-3 pt-1 pb-1'}>
                                <div className={'d-flex align-items-center p-0'}>
                                    <div dangerouslySetInnerHTML={{__html: item.icon}} />
                                    <small className={'m-2 mt-3'}>{item.title}</small>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                                     className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                          d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                                    <path fill-rule="evenodd"
                                          d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                                </svg>
                            </a>
                        </div>)
                    }
                </div>
            </div>
        </div>

        <div id={'bottom-menu-links'} className="row mt-5 mb-5">
            <div className="col-md-6 text-md-end text-center">
                <a className={'lead text-dark text-decoration-none'} href={'/'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                    </svg>
                    {t('title.back')}</a>
            </div>
            <div className="col-md-6 border-start text-md-start text-center mt-md-0 mt-5">
                <a href="https://lukyanchuk.com/cooperation" className={'lead text-dark text-decoration-none'}>{t('title.get-in-touch')} <svg
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    className="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"/>
                    <path fill-rule="evenodd"
                          d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"/>
                </svg></a>
            </div>
        </div>

    </div>
}

export default AboutMePage;
